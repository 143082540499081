import styled from "styled-components";
import { Link } from "gatsby";

export const PreviewLink = styled(Link)`
  display: block;
  width: 100%;
  height: auto;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const PreviewItem = styled.div``;
